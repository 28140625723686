<template>
  <v-dialog
    v-model="dialog"
    max-width="350"
  >
    <v-card>
      <v-card-title class="headline">
        {{ message.title }}
      </v-card-title>
      <v-card-text>
        <p v-for="(item, i) in message.text" :key="i">{{ item }}</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false" outlined>Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Information",
  data: () => ({
    dialog: false,
    message: []
  }),
  methods: {
    openDialog(msg) {
      this.dialog = true;
      this.message = msg;
    },
  }
}
</script>

<style>

</style>