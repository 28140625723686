<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row>
        <v-col
          v-for="(contact, index) in contacts"
          :key="index"
          cols="12"
          xs="12"
          sm="6"
          md="6"
        >
          <v-card class="mx-auto" max-width="300px" :class="{'grey lighten-2': contact.main_contact }">
            <v-card-text>
              <span class="title">{{contact.firstname}} {{contact.surname}} 
                <!-- <v-icon v-if="contact.main_contact" color="buttons">mdi-diamond-stone</v-icon> -->
              </span>
              <div class="subtitle-1"></div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="markVisible(contact)" rounded outlined :class="contact.visibility ? 'green lighten-3' : 'red lighten-4'">
                <label for v-if="contact.visibility" class="text-caption">
                  <v-icon left>mdi-eye-outline</v-icon>I'm public
                </label>
                <label for v-else class="text-caption">
                  <v-icon left>mdi-eye-off</v-icon>I'm hidden
                </label>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn class="ma-1" outlined small fab color="blue" @click="makeMain(contact.id)">
                <v-icon>mdi-diamond-stone</v-icon>
              </v-btn>
              <v-btn class="ma-1" outlined small fab color="green" @click="edit(contact)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn outlined small fab color="red" @click="deleteContact(contact.id)">
                <v-icon left>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <editIce ref="edit"/>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import editIce from "../dialogs/EditIce";
export default {
  name: "emergencyContactsComp",
  created() {
    //this.$store.dispatch('clearError')

    this.$store.dispatch("econtacts/aAll").then(response => {
      if (response) {
        this.loading = false;
        console.log(this.contacts)
      }
    });
  },
  components: {
    editIce
  },
  computed: {
    ...mapGetters("econtacts", {
      contacts: "gAll"
    }),
    
  },
  methods: {
    markVisible(emercont) {
      const newemercont = {
        visibility: !emercont.visibility
      };
      const id = emercont.id;
      //update at the database
      this.$store.dispatch("econtacts/aUpdate", {
        visibility: newemercont,
        id: id
      });
    },
    isMain(val){
      if (val)
        return "secondary lighten-5"
    },
    edit(contact){
      this.$refs.edit.open(contact);
    },
    deleteContact(id) {
      this.$store.dispatch("econtacts/aDelete", id);
    },
    makeMain(id) {
      this.$store.dispatch("econtacts/aMakeMain", id);
    },
    hasMain() {
      if (!this.hasMainContact) {
        this.mainContact =
          "Please set a main contact which will appear in the public view";
      }
    },
    foundMain() {
      this.hasMainContact = true;
      this.mainContact = "";
    }
  },
  makeMain(id) {
    this.$store.dispatch('econtacts/aMakeMain', id)
  },
  data() {
    return {
      loading: true,
      visibilityMod: [],
      mainContact: "Please set a main contact which will appear in the public",
      hasMainContact: false,
      main: "green lighten-4"
    };
  }
};
</script>

<style>
</style>