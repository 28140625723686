<template>
  <v-dialog v-model="dialog" max-width="600px">
    
    <v-card>
      <v-toolbar color="secondary">
        <v-toolbar-title>Edit Emergency Contact</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="mt-5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            label="Firstname"
            name="firstname"
            prepend-icon="mdi-account"
            type="text"
            v-model="ice.firstname"
            :rules="firstnameRules"
            required
            rounded
            dense
            outlined
          />
          <v-text-field
            label="Surname"
            name="surname"
            prepend-icon="mdi-account"
            type="text"
            v-model="ice.surname"
            :rules="surnameRules"
            required
            rounded
            dense
            outlined
          />
          <v-text-field
            label="Email"
            name="email"
            prepend-icon="mdi-mail"
            type="email"
            v-model="ice.email"
            :rules="emailRules"
            required
            rounded
            dense
            outlined
          />
          <v-select
            :items="relationships"
            label="Relationships"
            item-text="relationship"
            item-value="id"
            :rules="relationshipRules"
            v-model="ice.relationship_id"
            outlined
            rounded
            dense
                    
          ></v-select>

          <v-spacer>Phone Numbers</v-spacer>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Phone (Home)"
                name="data_of_birth"
                prepend-icon="mdi-account"
                type="text"
                v-model="ice.phone_home"
                rounded
                dense
                outlined
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Phone (Mobile)"
                :rules="mobileRules"
                name="data_of_birth"
                prepend-icon="mdi-account"
                type="text"
                v-model="ice.phone_mobile"
                rounded
                dense
                outlined
              />
            </v-col>
          </v-row>
          <v-spacer>Address</v-spacer>
          <v-text-field
            label="Street"
            name="street"
            prepend-icon="mdi-directions-fork"
            type="text"
            v-model="ice.street"
            rounded
            dense
            outlined
          />
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="City"
                name="data_of_birth"
                prepend-icon="mdi-account"
                type="text"
                v-model="ice.city"
                rounded
                dense
                outlined
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Post Code"
                name="post_code"
                prepend-icon="mdi-account"
                type="text"
                v-model="ice.post_code"
                rounded
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary darken-1" rounded @click="save">Update</v-btn>
        <v-btn color="secondary darken-1" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
  import { mapGetters } from "vuex";
  export default {
    data: () => ({
      dialog:false,
      valid: true,
      ice: {
        firstname: "",
        surname: "",
        relationship_id: "",
        email: "",
        phone_home: "",
        phone_mobile: "",
        street: "",
        post_code: "",
        city: ""
      },
      firstnameRules: [
        v => !!v || 'Firstname is required',
      ],
      surnameRules: [
        v => !!v || 'Surname is required',
      ],
      emailRules: [
        v => !!v || 'Email is required',
      ],
      relationshipRules: [
        v => !!v || 'Relationship is required',
      ],
      mobileRules: [
        v => !!v || 'A mobile number is required',
      ],
    }),
    created() {
      this.$store.dispatch("econtacts/aRelationships");
    },
    computed: {
      ...mapGetters("econtacts", {
        relationships: "gRelationships"
      })
    },
    methods: {
      open(contact) {
        this.dialog = true
        console.log(contact)
        this.ice = Object.assign(this.ice, contact);
      },
      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      save() {
        if (this.$refs.form.validate()) {
          //remove relationships
          delete this.ice.relationship;
          console.log(this.ice)
          this.$store.dispatch("econtacts/aEdit", this.ice).then(res => {
            if (res) {
              this.dialog = false;
            }
          });
        }
      }
    }
  }
</script>

<style>

</style>