<template>
  <v-main>
    <v-container fluid class="fill-height">
      <v-row>
        <v-col>
          <v-card class="mx-auto" max-width="600px">
            <v-toolbar color="secondary" dark>
              <v-toolbar-title>{{ title }}</v-toolbar-title>
              <v-btn color="secondary" class="ma-2" fab x-small @click="openInfo">
                <v-icon>mdi-information-variant</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <AddICE />
            </v-toolbar>
            <EmergencyContactView/>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Information ref="info"/>
  </v-main>
</template>

<script>
import AddICE from "@/components/dialogs/AddICE";
import Information from "@/components/dialogs/Information";
import EmergencyContactView from "@/components/views/EmergencyContactView";
//import AddMedications from "@/components/forms/AddMedication";
export default {
  name: "Medications",
  components: {
    AddICE,
    EmergencyContactView,
    Information
  },
  events: {
    callToggle() {
      this.toggleComp();
    }
  },
  data: () => ({
    //component: EmergencyContactView,
    title: "ICE Contacts",
    btnTitle: "Add",
    dialog: false,
    valid: true,
    dialogMessage: {
      title: "About ICE",
      text: [
        "ICE: In Case of Emergency",
        "You can unto add three (3) ICE contacts to your profile.",
        "By selecting the blue diamond, you make the contact your main contact."
      ]
    }
  }),
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    openInfo() {
      this.$refs.info.openDialog(this.dialogMessage);
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("user/aAddProfile", this.profile).then(res => {
          if (res) {
            this.dialog = false;
          }
        });
      }
    }
  }
}
</script>

<style>
</style>